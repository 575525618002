import * as React from "react";
interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const TruckOutline = (
  { size = 24, ...props }: Props,
  svgRef: React.Ref<SVGSVGElement>
) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={size}
      height={size}
      ref={svgRef}
      {...props}
    >
      <path
        fill="#fff"
        d="M9 17a2 2 0 11-4 0 2 2 0 014 0zm10 0a2 2 0 11-4 0 2 2 0 014 0z"
      />
      <path
        fill="#374151"
        d="M13 16h1-1zm0-8h-1 1zm4.293-.707L18 6.586l-.707.707zm3.414 3.414l.707-.707-.707.707zM4 6h8V4H4v2zm8 0v10h2V6h-2zM4 16V6H2v10h2zm1 0H4v2h1v-2zm7 0H9v2h3v-2zM2 16a2 2 0 002 2v-2H2zm10 0v2a2 2 0 002-2h-2zm0-10h2a2 2 0 00-2-2v2zM4 4a2 2 0 00-2 2h2V4zm10 12V8h-2v8h2zm0-8h2.586V6H14v2zm6 3.414V16h2v-4.586h-2zM16.586 8L20 11.414 21.414 10 18 6.586 16.586 8zM15 16h-1v2h1v-2zm5 0h-1v2h1v-2zm2-4.586A2 2 0 0021.414 10L20 11.414h2zM16.586 8L18 6.586A2 2 0 0016.586 6v2zM12 16a2 2 0 002 2v-2h-2zm8 0v2a2 2 0 002-2h-2zm-6-8V6a2 2 0 00-2 2h2zm-6 9a1 1 0 01-1 1v2a3 3 0 003-3H8zm-1 1a1 1 0 01-1-1H4a3 3 0 003 3v-2zm-1-1a1 1 0 011-1v-2a3 3 0 00-3 3h2zm1-1a1 1 0 011 1h2a3 3 0 00-3-3v2zm11 1a1 1 0 01-1 1v2a3 3 0 003-3h-2zm-1 1a1 1 0 01-1-1h-2a3 3 0 003 3v-2zm-1-1a1 1 0 011-1v-2a3 3 0 00-3 3h2zm1-1a1 1 0 011 1h2a3 3 0 00-3-3v2z"
      />
    </svg>
  );
};

const ForwardRef = React.forwardRef(TruckOutline);
export default ForwardRef;
