import * as React from "react";
interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const SearchCircleOutline = (
  { size = 24, ...props }: Props,
  svgRef: React.Ref<SVGSVGElement>
) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={size}
      height={size}
      ref={svgRef}
      {...props}
    >
      <path
        fill="#374151"
        d="M7.293 15.293a1 1 0 101.414 1.414l-1.414-1.414zM15 11a2 2 0 01-2 2v2a4 4 0 004-4h-2zm-4 0a2 2 0 012-2V7a4 4 0 00-4 4h2zm2-2a2 2 0 012 2h2a4 4 0 00-4-4v2zm-2.828 3.414l-2.88 2.879 1.415 1.414 2.879-2.879-1.414-1.414zM13 13a1.99 1.99 0 01-1.414-.586l-1.414 1.414A3.99 3.99 0 0013 15v-2zm-1.414-.586A1.99 1.99 0 0111 11H9a3.99 3.99 0 001.172 2.828l1.414-1.414zM20 12a8 8 0 01-8 8v2c5.523 0 10-4.477 10-10h-2zm-8 8a8 8 0 01-8-8H2c0 5.523 4.477 10 10 10v-2zm-8-8a8 8 0 018-8V2C6.477 2 2 6.477 2 12h2zm8-8a8 8 0 018 8h2c0-5.523-4.477-10-10-10v2z"
      />
    </svg>
  );
};

const ForwardRef = React.forwardRef(SearchCircleOutline);
export default ForwardRef;
