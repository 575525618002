import * as React from "react";
interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

const PresentationChartLineOutline = (
  { size = 24, ...props }: Props,
  svgRef: React.Ref<SVGSVGElement>
) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width={size}
      height={size}
      ref={svgRef}
      {...props}
    >
      <path
        fill="#374151"
        d="M4 4V3H3v1h1zm16 0h1V3h-1v1zM6.293 11.293a1 1 0 101.414 1.414l-1.414-1.414zM10 9l.707-.707a1 1 0 00-1.414 0L10 9zm3 3l-.707.707a1 1 0 001.414 0L13 12zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zM7.293 20.293a1 1 0 101.414 1.414l-1.414-1.414zM12 17l.707-.707a1 1 0 00-1.414 0L12 17zm3.293 4.707a1 1 0 001.414-1.414l-1.414 1.414zM3 3a1 1 0 000 2V3zm18 2a1 1 0 100-2v2zM4 5h16V3H4v2zm15-1v12h2V4h-2zm0 12H5v2h14v-2zM5 16V4H3v12h2zm0 0H3a2 2 0 002 2v-2zm14 0v2a2 2 0 002-2h-2zM7.707 12.707l3-3-1.414-1.414-3 3 1.414 1.414zm1.586-3l3 3 1.414-1.414-3-3-1.414 1.414zm4.414 3l4-4-1.414-1.414-4 4 1.414 1.414zm-5 9l4-4-1.414-1.414-4 4 1.414 1.414zm2.586-4l4 4 1.414-1.414-4-4-1.414 1.414zM3 5h18V3H3v2z"
      />
    </svg>
  );
};

const ForwardRef = React.forwardRef(PresentationChartLineOutline);
export default ForwardRef;
